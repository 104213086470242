import React from 'react';
import Layout from '../components/Layout';
import {Container, makeStyles, Typography} from "@material-ui/core";
import Iframe from 'react-iframe';
import {graphql, useStaticQuery} from "gatsby"

const useStyles = makeStyles(theme => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        height: '800px',
    },
    mapsIframe: {
        border: 0,
    },
}));

export default function App() {

    const classes = useStyles();
    const data = useStaticQuery(graphql`
    {
      heroImage:file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "lobby-1.jpg"}) {
        img:childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

    return (
        <Layout heroHeadline1="Kontakt"
                heroImage={data.heroImage.img.fluid}
        >
            <Container className={classes.cardGrid}>

                <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                    Anfahrt
                </Typography>
                <Typography gutterBottom style={{marginBottom: '20px'}}>
                    Wir haben im Hof kostenlose Parkplätze für Sie bereit. Öffentlich erreichen Sie uns über die
                    U-Bahnhaltestelle Heimeranplatz U4/U5.
                </Typography>
                <Iframe
                    url="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJxWv0k4t1nkcRFEtHS1iA80c&key=AIzaSyD0cN_3fxMECMbktgCd1oSAitUJ5C6nJNc"
                    width="100%"
                    height="90%"
                    id="dreyerpfeiffermap"
                    className="mapsIframe"
                    display="initial"
                    position="relative"
                    frameBorder="0"
                    allowFullScreen
                />


            </Container>
        </Layout>
    );
}
